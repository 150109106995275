var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.html",
          modifiers: { hover: true, html: true },
        },
      ],
      staticClass: "d-flex flex-row align-items-center justify-content-end",
      attrs: { title: _vm.tooltipText },
    },
    [
      _c("font-awesome-icon", {
        class: [
          { "recommend-icon__inactive": _vm.recommendId === 0 },
          "recommend-icon",
        ],
        attrs: { icon: ["far", "comment"], size: "lg" },
        on: {
          click: function ($event) {
            return _vm.iconClick()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }