<template>
	<div v-b-tooltip.hover.html :title="tooltipText" class="d-flex flex-row align-items-center justify-content-end">
		<font-awesome-icon
			@click="iconClick()"
			:icon="['far', 'comment']"
			size="lg"
			:class="[{ 'recommend-icon__inactive': recommendId === 0 }, 'recommend-icon']"
		></font-awesome-icon>
	</div>
</template>

<script>
import * as links from '@/router/links';

export default {
	props: ['contactId', 'recommendId', 'recommendValue', 'recommendIdTexts'],

	computed: {
		tooltipIdTexts() {
			return (this.recommendIdTexts || []).reduce((texts, recommendId) => {
				if (recommendId.value) {
					texts[recommendId.value] = recommendId.text;
				}
				return texts;
			}, {});
		},

		tooltipText() {
			const idText = this.tooltipIdTexts[this.recommendId];

			if (!idText) {
				return '';
			}

			const valueText = this.recommendValue;
			if (!valueText) {
				return idText;
			}

			return `${idText}<br>${valueText}`;
		},
	},

	methods: {
		iconClick() {
			this.openContactInNewWindow(this.contactId);
		},

		openContactInNewWindow(ContactId) {
			const contactRouteData = this.$router.resolve({
				name: links.contactDetailName,
				params: { ContactId },
			});

			window.open(contactRouteData.href, '_blank');
		},
	},
};
</script>

<style lang="scss" scoped>
.recommend-icon {
	cursor: pointer;

	&.recommend-icon__inactive {
		opacity: 0.05;
		transition: opacity 0.18s ease-in-out;

		&:hover {
			opacity: 1;
		}
	}
}
</style>